import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import PlainTextLayout from './PlainTextLayout';

const LicenseSection = () => {
  const { t } = useTranslation();

  return (
    <PlainTextLayout
      title={t('Volcaneers NFT License Agreement')}
      subtitle={t('LAST REVISED ON 8/24/2022')}
      id="license"
      content={[
        <>
          <b>
            <Trans>
              This Volcaneers NFT License Agreement (the “License”) is a legally binding agreement entered into between
              Paima Studios and you. When you acquire an Volcaneers NFT, you own all personal property rights to the
              token underlying the Volcaneers NFT (e.g., the right to freely sell, transfer, or otherwise dispose of
              that Volcaneers NFT), but you do not own the associated artwork, brand, or other intellectual property
              associated with that Volcaneers NFT, except for the specific licensed rights set forth below.
            </Trans>
          </b>
        </>,
        <>
          <b>
            <Trans>DEFINITIONS</Trans>
          </b>
          <br />
          <Trans>
            “Volcaneers NFT” means any Volcaneers branded or themed NFT created by or on behalf of Paima Studios.
          </Trans>
          <br />
          <Trans>
            “Volcaneers NFT Art” means the artwork, image or works of authorship associated with a specific Volcaneers
            NFT.
          </Trans>
          <br />
          <Trans>
            “Volcaneers Trademarks” means any and all logos, trademarks, service marks, and trade dress associated with
            Volcaneers, paimastudios.com, the Volcaneers NFTs, and Paima Studios, the “Volcaneer” name, or any other
            names of Volcaneers or Paima-related characters or products or service developed by us.
          </Trans>
          <br />
          <Trans>
            “Paima Studios,” “us,” “we,” or “our” means Paima Studios, LTD and/or its affiliates. “Commercial Use” means
            any activity that is performed with the intent to generate revenue, such as sale or transfer of items
            (including NFTs) on any marketplace, creating and selling merchandise or creating a comic book or video
            game. “NFT” means any blockchain-tracked non-fungible token, including those complying with the ERC-721A,
            ERC-721, ERC-1155, or other non-fungible token standard. “you” or “your” means a lawful owner of a
            Volcaneers NFT.
          </Trans>
        </>,
        <>
          <b>
            <Trans>LICENSE GRANT</Trans>
          </b>
          <br />
          <Trans>
            Volcaneers NFT Art License. For as long as you lawfully own your Volcaneers NFT, and subject to your
            compliance with the terms of this License, we hereby grant you the following rights:
          </Trans>
          <br />
          <br />
          <Trans>
            1. A non-exclusive, worldwide, royalty-free, license to use, reproduce, display, modify, and create
            derivative works of the Volcaneers NFT Art for your Volcaneers NFT for personal, non-commercial use (such as
            to display in your wallet, as a profile picture, to create a pixel-art version for use in a third party
            platform);
          </Trans>
          <br />
          <br />
          <Trans>
            2. A non-exclusive, worldwide, royalty-free, sub-licensable (but only to service providers assisting you
            with a Commercial Use) license to use, copy, reproduce and display the Volcaneers NFT Art for your
            Volcaneers NFT for any Commercial Use. The license in this sub-section (2) does not include a right to
            create derivative works of the Volcaneers NFT Art except as necessary to adapt and depict the Volcaneers NFT
            Art in or on the goods or media created pursuant to this license, or as otherwise required in the “No Rights
            to Trademarks” Section below. But this license does permit you to mint and create new NFTs and NFT projects
            based on your Volcaneers NFT Art for Volcaneers NFTs that you own, as long as you don't use the Volcaneers
            Trademarks on, or to promote, such new NFTs. From time to time, we may collaborate with third parties to
            create Volcaneers NFTs which include artwork, images, works of authorship, logos, trademarks, service marks,
            or trade dress owned by a third party (“Third Party Content”). The license in this paragraph does not extend
            to any Volcaneers NFT or Volcaneers NFT Art that contains Third Party Content, and you may not use, copy,
            reproduce, display, create derivative works of, or create new NFTs based on such Third Party Content, or any
            portion thereof, for any Commercial Use, unless we or the applicable third parties expressly provide our
            consent in writing or by public announcement.
          </Trans>
          <br />
        </>,
        <>
          <b>
            <Trans>MODIFICATIONS AND DERIVATIVE WORKS</Trans>
          </b>
          <br />
          <Trans>
            We recognize that you might want to create modifications and derivative works of your Volcaneers NFT Art,
            and we allow you to do so under the scope of the licenses granted above. However, you acknowledge and agree
            that Paima Studios may also modify, create derivative works of, and update any Volcaneers NFT Art and may
            create works of authorship similar or identical to your own adaptations, derivative works, and modifications
            of any Volcaneers NFT Art. Accordingly, on behalf of yourself and your heirs, successors and assigns, you
            irrevocably covenant and agree not to assert or bring any suit, claim, demand or challenge against Paima
            Studios or its affiliates or licensees in connection with their use of any Volcaneers NFT Art or any
            adaptations, derivative works, and modifications thereto, even if such artwork or content is similar to or
            the same as any adaptations, derivative works, or modifications in any Volcaneers NFT Art that have been
            created by you.
          </Trans>
          <br />
          <br />
          <Trans>
            No Rights to Trademarks. Nothing in this License will be interpreted to grant you any rights to any
            Volcaneers Trademarks belonging to Paima Studios. Without our written permission, you may not use any
            Volcaneers Trademarks for any Commercial Use, including to register any domain names or social media
            accounts using any Volcaneers Trademarks. This includes any Volcaneers Trademarks that may be displayed or
            contained in any Volcaneers NFT Art for your Volcaneers NFT (and you will need to modify the Volcaneers NFT
            Art to remove or obfuscate such Volcaneers Trademarks before making any Commercial Use of such Volcaneers
            NFT Art). You may not remove, delete or obscure any trademark notice, copyright notice or other intellectual
            property notice in any Volcaneers NFT or Volcaneers NFT Art.
          </Trans>
          <br />
          <br />
          <Trans>
            Transfer. The licenses granted above are non-transferrable, except that if you sell or transfer your
            Volcaneers NFT, you will no longer be granted the foregoing licenses in the Volcaneers NFT Art, and such
            license will transfer to the new owner of the Volcaneers NFT associated with such Volcaneers NFT Art. In
            connection with any sales, transfers or similar transactions of the Volcaneers NFTs, the transferee agrees
            that by purchasing, accepting, or otherwise acquiring the Volcaneers NFT, they shall be deemed to accept the
            terms of this License. You may not transfer an Volcaneers NFT to a transferee that is located in a country
            that is subject to a U.S. Government embargo, or that has been designated by the U.S. government as a
            terrorist-supporting country; or is otherwise listed on any U.S. Government list of prohibited or restricted
            parties.
          </Trans>
          <br />
          <br />
          <Trans>
            Restrictions. Notwithstanding any of the above, you may not use the Volcaneers NFT Art in any way that
            constitutes unlawful, defamatory, harassing, abusive, fraudulent, racist, hateful, vulgar, cruel, illegal or
            obscene activity, or that promotes any such activity.
          </Trans>
          <br />
          <br />
          <Trans>
            Reservation of Rights. All rights in and to the Volcaneers NFT Art and any other intellectual property of
            Paima Studios not expressly licensed herein are hereby reserved by Paima Studios. All goodwill arising from
            any use of the Volcaneers Trademarks will insure solely to Paima Studios and its affiliates.
          </Trans>
          <br />
        </>,
        <>
          <b>
            <Trans>DISCLAIMERS, LIMITATIONS OF LIABILITY, AND INDEMNIFICATION</Trans>
          </b>
          <br />
          <Trans>
            Disclaimers. YOUR ACCESS TO AND USE OF THE VOLCANEERS NFT AND VOLCANEERS NFT ART IS AT YOUR OWN RISK. YOU
            UNDERSTAND AND AGREE THAT THE VOLCANEERS NFTS AND VOLCANEERS NFT ART ARE PROVIDED TO YOU ON AN “AS IS” AND
            “AS AVAILABLE” BASIS. WITHOUT LIMITING THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW,
            PAIMA STUDIOS, ITS PARENTS, AFFILIATES, RELATED COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
            REPRESENTATIVES, PARTNERS AND LICENSORS (THE “PAIMA STUDIOS ENTITIES”) DISCLAIM ALL WARRANTIES AND
            CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
            NON-INFRINGEMENT. THE PAIMA STUDIOS ENTITIES MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL
            RESPONSIBILITY AND LIABILITY FOR: (A) THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, ORIGINALITY,
            SECURITY OR RELIABILITY OF THE VOLCANEERS NFTS AND VOLCANEERS NFT ART; (B) THE OPERATION OR COMPATIBILITY
            WITH ANY OTHER APPLICATION OR ANY PARTICULAR SYSTEM, DEVICE, BLOCKCHAIN, DIGITAL WALLET, HARDWARE OR
            MARKETPLACE; AND (C) WHETHER THE VOLCANEERS NFTS AND VOLCANEERS NFT ART WILL MEET YOUR REQUIREMENTS OR BE
            AVAILABLE ON AN UNINTERRUPTED, SECURE OR ERROR-FREE BASIS; AND (D) THE DELETION OF, OR THE FAILURE TO STORE
            OR TRANSMIT THE VOLCANEERS NFTS AND VOLCANEERS NFT ART. THE VOLCANEERS NFTS ARE INTENDED FOR CONSUMER
            ENJOYMENT, USE AND CONSUMPTION ONLY. THEY ARE NOT A “SECURITY,” AS DEFINED UNDER THE SECURITIES ACT OF 1933,
            AS AMENDED, THE SECURITIES EXCHANGE ACT OF 1934, AS AMENDED, THE INVESTMENT COMPANY ACT OF 1940, AS AMENDED,
            OR UNDER THE SECURITIES LAWS OF ANY U.S. STATE.
          </Trans>
          <br />
          <br />
          <Trans>
            Limitations of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT WILL THE PAIMA
            STUDIOS ENTITIES BE LIABLE (A) FOR ANY INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
            DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA OR
            PROFITS, BUSINESS INTERRUPTION OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR
            INABILITY TO USE THE SERVICES), HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER UNDER THIS LICENSE
            OR OTHERWISE ARISING IN ANY WAY IN CONNECTION WITH THE SERVICES OR THIS LICENSE AND WHETHER IN CONTRACT,
            STRICT LIABILITY OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) EVEN IF THE PAIMA STUDIOS ENTITIES HAVE BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, OR (B) FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER
            RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THIS LICENSE OR THE DELIVERY, USE OR PERFORMANCE OF
            THE SERVICES. THE MAXIMUM AGGREGATE LIABILITY OF THE PAIMA STUDIOS ENTITIES FOR ALL DAMAGES AND CAUSES OF
            ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, SHALL BE THE GREATER OF (I) $1,000 OR
            (II) THE AMOUNT YOU PAID PAIMA STUDIOS FOR YOUR VOLCANEERS NFT. SOME JURISDICTIONS (SUCH AS THE STATE OF NEW
            JERSEY) DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
            EXCLUSION OR LIMITATION MAY NOT APPLY TO YOU.
          </Trans>
          <br />
          <br />
          <Trans>
            Indemnification. By entering into this License and accessing or using the Volcaneers NFTs or Volcaneers NFT
            Art, you agree that you shall defend, indemnify and hold the Paima Studios Entities harmless from and
            against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and
            costs) incurred by the Paima Studios Entities arising out of or in connection with: (i) your violation or
            breach of any term of this License or any applicable law or regulation; (ii) your violation of any rights of
            any third party; (iii) your access to or use of the Volcaneers NFT or Volcaneers NFT Art; (iv) any
            modifications to or derivative works of the Volcaneers NFT Art you create, or (v) any fraud, negligence or
            wilful misconduct committed by you.
          </Trans>
        </>,
        <>
          <b>
            <Trans>ADDITIONAL PROVISIONS</Trans>
          </b>
          <br />
          <Trans>
            Additional Features. Paima Studios may choose to make additional features, access, content, items or other
            benefits available to owners of Volcaneers NFTs (“Additional Features”). Paima Studios has no duty or
            obligation to provide you with any Additional Features, and you should not expect any Additional Features
            when acquiring a Volcaneers NFT. Additional Features may be subject to additional terms and conditions,
            which may be presented to you at the time they are made available.
          </Trans>
          <br />
          <br />
          <Trans>
            Updating This License. We may modify this License from time to time. If we make changes that are material,
            we will use reasonable efforts to attempt to notify you, such as by placing a prominent notice on the first
            page of our website. However, it is your sole responsibility to review this License from time to time to
            view any such changes. Your continued access or use of the Volcaneers NFTs or Volcaneers NFT Art after the
            License has been updated will be deemed your acceptance of the modified License.
          </Trans>
          <br />
          <br />
          <Trans>
            Termination of License. If you breach any of the provisions of this License, all licenses granted by Paima
            Studios will terminate automatically. Upon the termination of your licenses, you shall cease all marketing,
            distribution, or sale of goods, services and media that feature the Volcaneers NFT Art and shall cease all
            further use of the Volcaneers NFT Art. All provisions which by their nature should survive the termination
            of this License shall continue in full force and effect subsequent to and notwithstanding any termination of
            this License by Paima Studios or you. Termination will not limit any of Paima Studios' other rights or
            remedies at law or in equity.
          </Trans>
          <br />
          <br />
          <Trans>
            Miscellaneous. If any provision of this License shall be unlawful, void or for any reason unenforceable,
            then that provision shall be deemed severable from this License and shall not affect the validity and
            enforceability of any remaining provisions. This License and the licenses granted hereunder may be freely
            assigned by Paima Studios but may not be assigned by you without the prior express written consent of Paima
            Studios. Any purported assignment in violation of this License will be null and void. No waiver by either
            party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent
            breach or default. You represent and warrant that you have the legal capacity to agree to this License. This
            License is governed by the laws of the State of New York, without regard to conflict of laws rules, and the
            proper venue for any disputes arising out of or relating to any of the same will be the state and federal
            courts located in New York City, New York. EACH PARTY HEREBY EXPRESSLY WAIVES ANY RIGHT TO A TRIAL BY JURY
            IN ANY ACTION OR PROCEEDING BROUGHT BY OR AGAINST EITHER PARTY IN CONNECTION WITH THIS LICENSE. You and
            Paima Studios agree that the United Nations Convention on Contracts for the International Sale of Goods will
            not apply to the interpretation or construction of this License.
          </Trans>
        </>
      ]}
      bottomNote={t('Paima Team')}
    />
  );
};

export default LicenseSection;
